<template>
   <div class="RefractiveDepartmentHome">
     <div>
       <ul>
         <li :class="qx.uf_ra_ls?'':'qx'" @click="go('/Iframe?type=20', qx.uf_ra_ls)">
           <img src="@/assets/img/conrea/contact_introduction.png" alt="">
           <p>角膜激光手术</p>
         </li>
         <li  :class="qx.uf_ra_dc?'':'qx'" @click="go('/Iframe?type=20&sstype=3', qx.uf_ra_dc)">
           <img src="@/assets/img/conrea/contact_inquiry.png" alt="">
           <p>晶体植入手术</p>
         </li>
         <li  :class="qx.uf_rgpolution?'':'qx'" @click="go('/corneaContactHome', qx.uf_rgpolution)">
           <img src="@/assets/img/conrea/contact_demo.png" alt="">
           <p>角膜接触镜验配</p>
         </li>
       </ul>
     </div>
   </div>
</template>

<script>
export default {
  name: "RefractiveDepartmentHome",
  data() {
    return {
      qx: ''
    }
  },
  created() {
    this.qx = this.$store.state.bases.qx
    // console.log(this.qx)
  },
  methods: {
    go(url, qx) {
      // console.log(qx)
      if (!qx) return this.$alert('请设置相关权限', '提示')
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped lang="scss">
.RefractiveDepartmentHome {
  width: 100vw;
  height: 87vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
h3 {padding: .5rem 0;}
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    padding: 0 .6rem;
    cursor: pointer;
    img {
      display: block;
      width: 160PX;
      margin-bottom: .2rem;
      @media (max-width: 1100px) {
        width: 130px;
      }
    }
  }
  .qx {
    cursor: not-allowed;
  }
}
.pd-4 {padding-top: 4vh}
</style>
